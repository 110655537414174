/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Footer from "../components/footer"
import SEO from "../components/seo"
import Layout from "../components/layout"

const Privacy = props => {
  return (
    <Layout>
      <SEO
        title="Privacy | tinyML Foundation"
        description="TinyML Summit. The topic is advances in ultra-low power Machine Learning technologies and applications."
      />
      <div className="grid-container gap-xxxl" sx={{ paddingTop: `200px` }}>
        <h1>Privacy</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: props.data.wpNotice.notices.content,
          }}
        ></div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    wpNotice(title: { eq: "Privacy" }) {
      notices {
        content
      }
    }
  }
`
export default Privacy
